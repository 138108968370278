import React from 'react';
import Image from 'react-bootstrap/Image';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import '../assets/css/Header.css';

const Header = () => {
    return (
        <>
            <header className="header">
                <Navbar expand="lg">
                    <Container>                        
                            <h2 className="loader wow fadeInLeft text-white text-center w-100" data-wow-delay="0.2s" data-wow-duration="1.2s" style={{  cssText: 'font-family: WriteSong; font-size: 3rem' }}>
                                <span>M</span>
                                <span>i</span>
                                <span>s</span>
                                <span>Q</span>
                                <span>u</span>                                
                                <span>i</span>                                
                                <span>n</span>                                
                                <span>c</span>                                
                                <span>e</span>                                
                            </h2>
                        
                    </Container>
                </Navbar>
            </header>
        </>
    )
}
export default Header;

