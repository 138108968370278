import React from 'react';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Fancybox from "./Fancybox-gallery";
import '../assets/css/Footer.css';

const Footer = () => {
    return (
        <>
            <footer className="footer" style={{ cssText: 'background-color: #972626 !important' }}>
                <div className="wpo-lower-footer  has-not-copyright text-center" style={{ cssText: 'background-color: #972626 !important' }}>
                    <Container>
                        <div className="row">
                            <div className="separator"></div>
                            <div className="col col-xs-12">
                                <p>© Copyright 2024 | <a href="#/">Mis quince - Nicole </a></p>
                                <p>Contrataciones | <a href="#/"> 221 864 2147</a></p>    
                                  </div>

                        </div>
                    </Container>
                </div>
            </footer>
        </>
    )
}

export default Footer;