import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/css/Banner.css';
import '../assets/css/Carousel-custom.css';
const Announce_banner = () => {
    return (
        <>            
            <section className="wedding_banner" id='home'>
                <div className="wedding_announcement" style={{ top: 0, marginTop:'50px' }}>
                    <h2 className="loader wow fadeInLeft" data-wow-delay="0.2s" data-wow-duration="1.2s" style={{ marginBottom: 0, cssText: 'margin-top: 23vh !important;' }}>
                        <span>N</span>
                        <span>i</span>
                        <span>c</span>
                        <span>o</span>
                        <span>l</span>
                        <span>e</span>
                    </h2>
                </div>
            </section>
            <iframe
                src="https://missael306.github.io/galeria/"
                width="100%"
                height="600"
                style={{ border: "none", zIndex:-99 }}
            ></iframe>  
                    
        </>
    )
}
export default Announce_banner;