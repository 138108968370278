import React, { useState, useEffect } from 'react';
import Image from 'react-bootstrap/Image';
import Container from 'react-bootstrap/Container';
import WOW from "wowjs";
import '../assets/css/OurLoverStory.css'
import { PiChurch } from "react-icons/pi";

const OurLovestory = () => {
    const [wow, setWow] = useState(new WOW.WOW())
    useEffect(() => {
        setWow(wow.init())
    }, []);
    return (
        <>
            <section className="love_story section-padding mb-5" id="story2" >
                <Container>
                    <div className="row">
                        <div className="col col-xs-12">
                            <div className="story-timeline">
                                <div className="row">
                                    <h3 className='text-center wow fadeInLeft eyesome' data-wow-delay="0.8s" data-wow-duration="1.5s" style={{ cssText: 'margin-bottom:0 !important; margin-top:50px' }}>Opciones de regalo</h3>
                                    <div className="col col-lg-6 col-8 mx-auto text-holder mt-3 ">
                                        <div className="story-text wow fadeInLeft" data-wow-delay="0.8s" data-wow-duration="1.5s" style={{ cssText: 'padding: 0 !important; margin:0 !important; he' }}>
                                            <Image src={require('../assets/images/sobre.png')} alt="image" className="img-fluid img img-responsive wow fadeInLeft" data-wow-delay="0.4s" data-wow-duration="1.5s" />
                                        </div>
                                    </div>
                                    <div className="story-text wow fadeInLeft" data-wow-delay="0.8s" data-wow-duration="1.5s" style={{ cssText: 'padding-top: 0 !important; margin-top:0 !important' }}>
                                        <p className='write-song title2' style={{ cssText: 'margin-bottom: 0;' }}><b>¡Lluvia de sobres!</b></p>
                                        <p className='write-song interlineado' style={{ cssText: 'font-size: 2rem !important;' }}>La lluvia de sobres es la tradición de regalar dinero en efectivo dentro de un sobre el día del evento.</p>
                                    </div>

                                    <div className="col col-lg-6 col-8 mx-auto text-holder mt-3 ">
                                        <div className="story-text wow fadeInLeft" data-wow-delay="0.8s" data-wow-duration="1.5s" style={{ cssText: 'padding: 0 !important; margin:0 !important; he' }}>
                                            <Image src={require('../assets/images/regalo2.png')} alt="image" className="img-fluid img img-responsive wow fadeInLeft" data-wow-delay="0.4s" data-wow-duration="1.5s" />
                                        </div>
                                    </div>                                                                        
                                    <div className="story-text wow fadeInLeft" data-wow-delay="0.8s" data-wow-duration="1.5s" style={{ cssText: 'padding-top: 0 !important; margin-top:0 !important' }}>
                                        <p className='write-song title2' style={{ cssText: 'margin-bottom: 0;' }}><b>¡Regalo sorpresa!</b></p>
                                        <p className='write-song' style={{ cssText: 'font-size: 2rem !important;' }}>Estoy segura que me encantará.</p>
                                    </div>                                    
                                </div>                                
                            </div>
                        </div>
                    </div>
                </Container>
                
            </section>            
        </>
    )
}

export default OurLovestory;