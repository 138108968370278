import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import WOW from "wowjs";
import '../assets/css/Couple-Intro.css';
import { FaCross } from "react-icons/fa";

const Intro = () => {
    const [wow, setWow] = useState(new WOW.WOW())
    useEffect(() => {
        setWow(wow.init())
    }, []);

    return (
        <>
            <section className="couple-section section-padding" id="couple">

                <Container>
                    <div className="shape_1"><Image src={require('../assets/images/esquina2.png')} className="img-fluid" alt="side-img" width="100%" /></div>
                    <div className="row align-items-center z_index">
                        <div className="col-lg-3 text-center text-lg-end bride wow fadeInDown mt-5 write-song " data-wow-delay="0.1s" data-wow-duration="1.2s" >
                            <p className='mt-4'>¡Llegó el día!</p>
                            <p>Queda en el recuerdo mi niñez y comienza una etapa que me permitirá reconocer nuevos retos y metas para cumplir mis sueños.</p>
                            <p >Estoy construyendo mi propio camino y deseo compartirlo rodeada de todos los que quiero.</p>                            
                            <br />
                            <br />
                            <h3 class='eyesome mt-1'>Mis papás</h3>
                            <p className='mb-0'>Jessica Herrera Tapia</p>
                            <p>Javier Vargas Campos</p>
                        </div>
                        <div className="col-lg-6 text-center wow fadeInUp" data-wow-delay="0.1s" data-wow-duration="1.2s">
                            <div className="middle-couple-pic">
                                <Image src={require('../assets/images/campi8.png')} alt="image" className='img-fluid' />
                            </div>
                        </div>
                        <div className="col-lg-3 text-center text-lg-start groom wow fadeInDown write-song" data-wow-delay="0.1s" data-wow-duration="1.2s">
                            <br/>
                            <h3 className='eyesome'>Mis padrinos</h3>
                            <p className='mb-0'>Emilia Conde Flores</p>
                            <p>Jorge Campos Fuentes <FaCross /></p>                            
                        </div>
                        <div className="col-lg-6 text-center wow fadeInDown" data-wow-delay="0.1s" data-wow-duration="1.2s">
                            <div className="middle-couple-pic">
                                <Image src={require('../assets/images/campi10.png')} alt="image" className='img-fluid' />
                            </div>
                        </div>                        
                    </div>
                </Container>
            </section>
        </>
    )
}
export default Intro;