import React, { useState, useEffect } from 'react';
import Image from 'react-bootstrap/Image';
import Container from 'react-bootstrap/Container';
import WOW from "wowjs";
import '../assets/css/OurLoverStory.css'
import { PiChurch } from "react-icons/pi";

const OurLovestory = () => {
    const [wow, setWow] = useState(new WOW.WOW())
    useEffect(() => {
        setWow(wow.init())
    }, []);
    return (
        <>
            <section className="love_story section-padding mt-5" id="story" >
                <div className="col-lg-3 text-center text-lg-start groom wow fadeInRight mt-5" data-wow-delay="0.1s" data-wow-duration="1.2s">
                    <h3 className='text-center eyesome'>Sábado <span className='letter-16'>16</span> Noviembre </h3>
                </div>
                <Container>
                    <div className="row">
                        <div className="col col-xs-12">
                            <div className="story-timeline">
                                <div className="round-shape"></div>
                                <div className="row order_column">
                                    <div className="col col-lg-6 col-12">
                                        <Image src={require('../assets/images/calendario.png')} alt="image" className="img-fluid img img-responsive wow fadeInLeft" data-wow-delay="0.4s" data-wow-duration="1.5s" />
                                    </div>
                                </div>
                                <div className="row">
                                    <h3 className='text-center wow fadeInLeft eyesome' data-wow-delay="0.8s" data-wow-duration="1.5s" style={{ cssText: 'margin-bottom:0 !important' }}>Ceremonia Religiosa</h3>
                                    <div className="col col-lg-6 col-8 mx-auto text-holder ">
                                        <div className="story-text wow fadeInLeft" data-wow-delay="0.8s" data-wow-duration="1.5s" style={{ cssText: 'padding: 0 !important; margin:0 !important; he' }}>
                                            <Image src={require('../assets/images/iglesia.png')} alt="image" className="img-fluid img img-responsive wow fadeInLeft" data-wow-delay="0.4s" data-wow-duration="1.5s" />
                                        </div>
                                    </div>
                                    <div className="col col-lg-6 col-12 text-holder ">
                                        <span className="heart">
                                            <i className="fi flaticon-calendar wow zoomIn" data-wow-delay="0.5s" data-wow-duration="1s"></i>
                                        </span>
                                        <div className="story-text wow fadeInLeft" data-wow-delay="0.8s" data-wow-duration="1.5s" style={{ cssText: 'padding-top: 0 !important; margin-top:0 !important' }}>
                                            <p className='write-song title2' style={{ cssText: 'margin-bottom: 0; line-height: 40px' }}>Parroquia provisional de Tepexco</p>
                                            <p className='write-song' style={{ cssText: 'font-size: 2rem !important;' }}>Calle Francisco I. Madero</p>
                                            <p className='write-song' style={{ cssText: 'font-size: 2.5rem !important;' }} ><b>1:30 pm</b></p>
                                        </div>
                                        <a href="https://www.google.com/maps/place/Francisco+I+Madero,+Centro,+74550+Tepexco,+Pue./@18.6401637,-98.6906647,17z/data=!3m1!4b1!4m6!3m5!1s0x85ce58450b8f0569:0x64ee88be74bbe30f!8m2!3d18.6401637!4d-98.6906647!16s%2Fg%2F11c2tgb979?entry=ttu&g_ep=EgoyMDI0MTAyOS4wIKXMDSoASAFQAw%3D%3D" target='_blank' style={{ cssText: 'text-decoration: underline; text-decoration-color: white; font-size: 2rem !important' }}>
                                            <div className="button story-text wow fadeInLeft write-song" data-wow-delay="0.8s" data-wow-duration="1.5s" style={{ cssText: 'font-size: 2rem !important; margin-top: 0 !important; color: white' }}>
                                                ¿Cómo llegar?
                                            </div>
                                        </a>

                                        <h3 className='text-center wow fadeInLeft eyesome' data-wow-delay="0.8s" data-wow-duration="1.5s" style={{ cssText: 'margin-bottom:0 !important; margin-top:50px' }}>Recepción</h3>
                                        <div className="col col-lg-6 col-8 mx-auto text-holder mt-3 ">
                                            <div className="story-text wow fadeInLeft" data-wow-delay="0.8s" data-wow-duration="1.5s" style={{ cssText: 'padding: 0 !important; margin:0 !important; he' }}>
                                                <Image src={require('../assets/images/cheers.png')} alt="image" className="img-fluid img img-responsive wow fadeInLeft" data-wow-delay="0.4s" data-wow-duration="1.5s" />
                                            </div>
                                        </div>
                                        <span className="heart">
                                            <i className="fi flaticon-calendar wow zoomIn" data-wow-delay="0.5s" data-wow-duration="1s"></i>
                                        </span>
                                        <div className="story-text wow fadeInLeft" data-wow-delay="0.8s" data-wow-duration="1.5s" style={{ cssText: 'padding-top: 0 !important; margin-top:0 !important' }}>
                                            <p className='write-song title2' style={{ cssText: 'margin-bottom: 0; line-height: 40px' }}>Seminario Conciliar de Tepexco</p>
                                            <p className='write-song' style={{ cssText: 'font-size: 2rem !important; line-height: 40px' }}>Calle Independencia Oriente 44, Centro, 74550 Tepexco, Pue.</p>
                                            <p className='write-song' style={{ cssText: 'font-size: 2.5rem !important;' }} ><b>3:00 pm</b></p>
                                        </div>

                                        <a href="https://www.google.com/maps/place/Seminario+Conciliar+de+M%C3%A9xico+Casa+Tepexco/@18.6423657,-98.6853089,18z/data=!4m6!3m5!1s0x85ce59f4224fa361:0xc5fd52765454c0f2!8m2!3d18.6424339!4d-98.685257!16s%2Fg%2F11q9dmr8k7?entry=ttu&g_ep=EgoyMDI0MTAyNy4wIKXMDSoASAFQAw%3D%3D" target='_blank' style={{ cssText: 'text-decoration: underline; text-decoration-color: white; font-size: 2rem !important' }}>
                                            <div className="button story-text wow fadeInLeft write-song" data-wow-delay="0.8s" data-wow-duration="1.5s" style={{ cssText: 'font-size: 2rem !important; margin-top: 0 !important; color: white' }}>
                                                ¿Cómo llegar?
                                            </div>
                                        </a>

                                        <h3 className='text-center wow fadeInLeft eyesome' data-wow-delay="0.8s" data-wow-duration="1.5s" style={{ cssText: 'margin-bottom:0 !important; margin-top:50px' }}>Código de vestimenta</h3>
                                        <div className="col col-lg-6 col-8 mx-auto text-holder mt-3 ">
                                            <div className="story-text wow fadeInLeft" data-wow-delay="0.8s" data-wow-duration="1.5s" style={{ cssText: 'padding: 0 !important; margin:0 !important; he' }}>
                                                <Image src={require('../assets/images/dress_code.png')} alt="image" className="img-fluid img img-responsive wow fadeInLeft" data-wow-delay="0.4s" data-wow-duration="1.5s" />
                                            </div>
                                        </div>
                                        <span className="heart">
                                            <i className="fi flaticon-calendar wow zoomIn" data-wow-delay="0.5s" data-wow-duration="1s"></i>
                                        </span>
                                        <div className="story-text wow fadeInLeft" data-wow-delay="0.8s" data-wow-duration="1.5s" style={{ cssText: 'padding-top: 0 !important; margin-top:0 !important' }}>
                                            <p className='write-song title2' style={{ cssText: 'margin-bottom: 0;' }}>Formal o Cóctel</p>
                                            <p className='write-song' style={{ cssText: 'font-size: 2.5rem !important; line-height: 40px;' }} ><b>El color rojo queda reservado para la quinceañera.</b></p>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </Container>
            </section>
        </>
    )
}

export default OurLovestory;