import React, { useState, useEffect } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import Image from 'react-bootstrap/Image';
import Container from 'react-bootstrap/Container';
import WOW from "wowjs";
import '../assets/css/Rsvp.css';
import Title from './Section-Title';
import Swal from 'sweetalert2';
import '../assets/css/Custom.css';

const RSVP = (props) => {
    const [wow, setWow] = useState(new WOW.WOW());
    useEffect(() => {
        wow.init();
    }, [wow]);

    const [forms, setForms] = useState({
        familia: '',
        invitados: '',
        confirma: 'Si',
        deseo: ''
    });

    const [validator] = useState(new SimpleReactValidator({
        className: 'errorMessage',
        messages: {
            required: 'Este campo es obligatorio.',
            alpha_space: 'Solo se permiten letras y espacios.',
            email: 'Debe ser un correo electrónico válido.',
            max: 'No puede tener más de :max caracteres.',
            min: 'Debe tener al menos :min caracteres.',
        }
    }));

    const changeHandler = e => {
        setForms({ ...forms, [e.target.name]: e.target.value });
        if (validator.allValid()) {
            validator.hideMessages();
        } else {
            validator.showMessages();
        }
    };

    const submitHandler = async (e) => {
        e.preventDefault();

        // Create FormData from the current state
        const formData = new FormData();
        for (const key in forms) {
            formData.append(key, forms[key]);
        }

        try {
            const response = await fetch("https://script.google.com/macros/s/AKfycbzfk9fSYPnz3ebiSSzmhf2s8r3sAzmOt7JPbJ0IcROXBB_ybpesd9Kfd4aRmwFdghAm/exec", {
                method: "POST",
                body: formData
            });

            if (response.ok) {
                const result = await response.json();
                Swal.fire({
                    title: '¡Gracias por confirmar!',
                    confirmButtonText: 'Aceptar',
                    customClass: {
                        confirmButton: 'custom-confirm-button' // Clave para personalizar el botón
                    }
                });
            } else {
                Swal.fire({
                    title: '¡Gracias por confirmar!',
                    confirmButtonText: 'Aceptar',
                    customClass: {
                        confirmButton: 'custom-confirm-button' // Clave para personalizar el botón
                    }
                });
            }
        } catch (error) {
            Swal.fire({
                title: '¡Gracias por confirmar!',
                confirmButtonText: 'Aceptar',
                customClass: {
                    confirmButton: 'custom-confirm-button' // Clave para personalizar el botón
                }
            });
        }
    };

    return (
        <>
            <section>
                <Container>
                    <div className="row align-items-center z_index">
                        <div className="col-lg-6 text-center wow fadeInUp" data-wow-delay="0.1s" data-wow-duration="1.2s">
                            <div className="middle-couple-pic">
                                <Image src={require('../assets/images/campi12.png')} alt="image" className='img-fluid' />
                            </div>
                        </div>
                    </div>
                </Container>
            </section>

            <div className="row align-items-center z_index" style={{ cssText: 'padding: 0 !important; margin:0 !important;' }}>
                <div className="col-lg-3 text-center text-lg-end bride wow fadeInDown mt-5 write-song " data-wow-delay="0.1s" data-wow-duration="1.2s">
                    <div className="col col-lg-6 col-8 mx-auto text-holder mt-3 ">
                        <div className="story-text wow fadeInLeft" data-wow-delay="0.8s" data-wow-duration="1.5s" style={{ cssText: 'padding: 0 !important; margin:0 !important;' }}>
                            <Image src={require('../assets/images/album.png')} alt="image" className="img-fluid img img-responsive wow fadeInLeft" data-wow-delay="0.4s" data-wow-duration="1.5s" />
                        </div>
                    </div>
                    <p>Queremos que cada uno de ustedes sea parte de este momento especial, así que no olvides capturar los mejores recuerdos de la noche.</p>
                    <p>Crearemos un álbum compartido donde podrán subir todas las fotos que tomen. Así ¡podremos revivir todos los momentos divertidos!</p>
                </div>

                <div className="col col-xs-12" style={{ cssText: 'padding: 0 !important; margin:0 !important;' }}>
                    <a href="https://weduploader.com/upload/2RQpDJGoJ0qbfeRB" target='_blank' style={{ cssText: 'text-decoration: underline; text-decoration-color: white; font-size: 2rem !important' }}>
                        <div className="button story-text wow fadeInLeft write-song text-center" data-wow-delay="0.8s" data-wow-duration="1.5s" style={{ cssText: 'font-size: 2rem !important; margin-top: 0 !important; color: white' }}>
                            Ir al albúm
                        </div>
                    </a>
                </div>
            </div>

            <section className="rsvp_section section-padding pb-0" id="rspv">
                <Container>
                    <div className="row justify-content-center">
                        <div className="d-none col-md-3 col-xl-3 d-lg-flex align-items-end">
                            <div className="bg_1">
                                <Image src={require('../assets/images/rsvp/1.png')} alt="image" className="img-fluid wow fadeInLeft" data-wow-delay="0.8s" data-wow-duration="1.5s" />
                            </div>
                        </div>

                        <div className="col-lg-6 col-xl-6">
                            <div className="content_wrapper">
                                <div className="rsvpForm_area">
                                    <Title sec_title={'Are you attending?'} style={{ cssText: 'margin-bottom: 0 !important;' }} />
                                    <form onSubmit={submitHandler} className="contact-validation-active">
                                        <div className="form-field">
                                            <textarea
                                                id="familia"
                                                name="familia"
                                                value={forms.familia}
                                                onChange={changeHandler}
                                                required
                                                className="form-control"
                                                placeholder="Familia"
                                            />
                                        </div>
                                        <div className="form-field">
                                            <input
                                                type="text"
                                                id="invitados"
                                                name="invitados"
                                                value={forms.invitados}
                                                onChange={changeHandler}
                                                required={forms.confirma === 'Si'}
                                                className="form-control"
                                                placeholder="Número de invitados"
                                            />
                                        </div>
                                        <div className="form-field radio-buttons">
                                            <p className="mb-0">
                                                <input
                                                    type="radio"
                                                    id="confirma"
                                                    name="confirma"
                                                    value="Si"
                                                    onChange={changeHandler}
                                                    className="d-none"
                                                    checked={forms.confirma === 'Si'}
                                                />
                                                <label htmlFor="confirma">Si asistiré</label>
                                            </p>
                                            <p className="mb-0">
                                                <input
                                                    type="radio"
                                                    id="not"
                                                    name="confirma"
                                                    value="No"
                                                    onChange={changeHandler}
                                                    className="d-none"
                                                />
                                                <label htmlFor="not">No asistiré</label>
                                            </p>
                                        </div>
                                        <div className="form-field">
                                            <textarea
                                                id="deseo"
                                                name="deseo"
                                                value={forms.deseo}
                                                onChange={changeHandler}
                                                required
                                                className="form-control"
                                                placeholder="Envíame un deseo"
                                            />
                                        </div>
                                        <div className="submit-area">
                                            <button type="submit" className="theme-btn">Enviar</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="d-none d-lg-block col-md-3 col-xl-3">
                            <div className="bg_2">
                                <Image src={require('../assets/images/rsvp/2.png')} alt="image" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </Container>
            </section>

            <div className="row align-items-center z_index esquina-flor" style={{ cssText: 'padding: 0 !important; margin:0 !important; he' }}>
                <div className="col-lg-3 text-center text-lg-end bride wow fadeInDown mt-5 write-song " data-wow-delay="0.1s" data-wow-duration="1.2s">
                    <p className='custom-margin'>Esperamos contar con tu presencia para celebrar juntos y crear momentos inolvidables. <br /><br /><b className='eyesome'>¡No faltes!</b> </p>
                </div>                                
            </div>            
        </>
    );
}

export default RSVP;
