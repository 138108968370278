import React, { useState, useEffect } from 'react';
import ReactAudioPlayer from 'react-audio-player';

function AudioPlayer() {
  const [isVisible, setIsVisible] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsVisible(window.scrollY === 0);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const togglePlay = () => {
    setIsPlaying((prev) => !prev);
  };

  return (
    <div
      style={{
        position: 'absolute',
        top: '70px',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 1000,
        display: isVisible ? 'block' : 'none',
      }}
    >
      <div
        onClick={togglePlay}
        style={{
          width: '40px',
          height: '40px',
          borderRadius: '50%',
          backgroundColor: 'transparent',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          cursor: 'pointer',
        }}
      >
        {isPlaying ? (
          // Icono de Pause en negro
          <svg width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 5h4v14H6V5zm8 0h4v14h-4V5z" fill="black"/>
          </svg>
        ) : (
          // Icono de Play en negro
          <svg width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 5v14l11-7L8 5z" fill="black"/>
          </svg>
        )}
      </div>

      {isPlaying && (
        <ReactAudioPlayer
          src={require('../assets/images/musica.mp3')}
          autoPlay
          controls={false}
          style={{ display: 'none' }}
        />
      )}
    </div>
  );
}

export default AudioPlayer;
